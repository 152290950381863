import "jquery-slimscroll";
import "./jquery-ui-1.10.3.custom.min";
require("../shared/application");
import "flatpickr";
// === locations div + google map === //
window.locations = {
  init: function (path, count) {
    locations.elements = {
      $map: $("#map-canvas"),
      $locationsListScroll: $("#locations-list-scroll"),
      $locationsListText: $("#locations-list-text"),
      $showAllLocations: $("#show-all-locations"),
      $showAllLocationsHeader: $("#show-all-locations-header"),
      $showSomeLocationsHeader: $("#show-some-locations-header"),
      locationsPath: path,
      $recenterBtn: $("#recenter-map"),
      count: count,
    };

    locations.initScroll();
    locations.drawMap();
    locations.bindEvents();
  },

  initScroll: function () {
    locations.elements.$locationsListScroll.slimScroll({
      height: "380px",
      railVisible: true,
      alwaysVisible: false,
      color: "#777",
      railColor: "#ccc",
      railOpacity: 0.3,
    });
  },

  drawMap: function () {
    $("#map-canvas").goMap({
      maptype: "ROADMAP",
    });

    $.getJSON(locations.elements.locationsPath, function (data) {
      $.each(data, function () {
        var content = "<p>" + locations.getLocationHTMLFromJson(this) + "</p>";

        // add it to the map
        var html = null;
        if (locations.elements.count >= 1) var html = { content: content };
        locations.elements.marker = $.goMap.createMarker({
          latitude: this["lat"],
          longitude: this["lng"],
          title: this["name"],
          id: "#marker-" + this["id"],
          html: html,
        });
        $(
          "<li id='marker-link-" + this["id"] + "'>" + content + "</li>"
        ).appendTo(locations.elements.$locationsListScroll);
      });
    }).done(function () {
      if (locations.elements.count == 1 && locations.elements.marker) {
        $.goMap.map.setCenter(locations.elements.marker.getPosition());
        $.goMap.map.setZoom(16);
      } else {
        $.goMap.fitBounds();
      }
    });
  },

  bindEvents: function () {
    locations.elements.$locationsListScroll.on("click", "li", function () {
      var id = $(this).attr("id").replace(/\D/g, "");
      google.maps.event.trigger(
        $($.goMap.mapId).data("#marker-" + id),
        "click"
      );
      $.goMap.map.setZoom(16);
    });

    locations.elements.$recenterBtn.on("click", function () {
      if (locations.elements.count == 1) {
        $.goMap.map.setCenter(locations.elements.marker.getPosition());
        $.goMap.map.setZoom(16);
      } else {
        $.goMap.fitBounds();
      }
      $.goMap.clearInfo();
    });

    locations.elements.$showAllLocations.on("click", function (e) {
      locations.elements.$locationsListText.empty();
      locations.drawFullList();
      locations.elements.$showSomeLocationsHeader.hide();
      locations.elements.$showAllLocationsHeader.show();
      e.preventDefault();
    });
  },

  drawFullList: function () {
    $.getJSON(locations.elements.locationsPath, function (data) {
      var i = 0;
      var odd_even = new Array("odd", "even");
      $.each(data, function () {
        var content =
          '<li class="' +
          odd_even[i++ % 2] +
          '">' +
          locations.getLocationHTMLFromJson(this) +
          "</li>";
        locations.elements.$locationsListText.append(content);
      });
    });
  },

  getLocationHTMLFromJson: function (obj) {
    var content = "";
    if (obj["name"] && obj["name"] != "")
      content += "<strong>" + obj["name"] + "</strong><br />";
    content += obj["address1"] + "<br />";
    if (obj["address2"] && obj["address2"] != "")
      content += obj["address2"] + "<br />";
    content += obj["city"] + ", " + obj["state"] + ", " + obj["zip"] + "<br />";
    if (obj["phone"] && obj["phone"] != "")
      content += locations.numberToPhone(obj["phone"]);
    return content;
  },

  numberToPhone: function (num) {
    return num.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  },
};

//$(document).ready(function() {
var loggedInUserEmail = $("#cart_item_gift_attributes_sender_reply_to").val();
// === generic === //
var storefront = {
  init: function () {
    storefront.vars = {
      // delivery + add to cart
      $deliveryRadios: $(".delivery-radio"),
      $deliveryOptions: $(".delivery-options"),
      $deliveryOptionsInputs: $(".delivery-options :input"),
      defaultDeliveryOption: "virtual",
      currentDeliveryOption: "virtual",
      $cartItemForm: $("#cart-item-form"),
      $cartItemFormSubmit: $("#cart-item-form :submit"),
      $sendGiftSelect: $(".gift-schedule-select"),
      $sendGiftFutureHelp: $(".gift-schedule-select").next(".help-block"),
      $sendGiftFuture: $(".gift-schedule-future"),
      giftIdPrepend: "cart_item_gift_attributes",

      // content
      $about: $(".about-text"),
      $moreAbout: $("#more-about"),
      $lessAbout: $("#less-about"),
    };

    storefront.bindEvents();
    storefront.selectDeliveryOption(storefront.vars.defaultDeliveryOption);

    $(".gift-send-date").flatpickr({ dateFormat: "m/d/Y" });
    //flatpickr-datepicker
    // $(".gift-send-date").datepicker();
  },

  bindEvents: function () {
    // delivery options
    storefront.vars.$deliveryRadios.on("click", function (e) {
      storefront.vars.currentDeliveryOption = $(this).val();
      storefront.selectDeliveryOption($(this).val());
    }),
      storefront.vars.$sendGiftSelect.on("change", function (e) {
        if ($(this).val() == "future") {
          storefront.vars.$sendGiftFuture
            .find(":input")
            .attr("disabled", false);
          storefront.vars.$sendGiftFuture.removeClass('d-none').addClass('d-flex');
          storefront.vars.$sendGiftFutureHelp.show();
        } else {
          storefront.resetGiftDefault();
        }
      }),
      // cart item submit
      storefront.vars.$cartItemForm.on("submit", function (e) {
        e.preventDefault();

        storefront.vars.$cartItemFormSubmit.attr("disabled", true);
        if (
          storefront.vars.currentDeliveryOption == "gift" &&
          !storefront.validateGift()
        ) {
          storefront.enableSubmitButton();
          return false;
        }
        var request = $.ajax({
          url: $(this).attr("action"),
          data: $(this).serialize(),
          type: "post",
          success: function (data, textStatus, jqXHR) {
            storefront.resetDeliveryOptions();
            nav.reload();
            storefront_actions.reload();
            fbq("track", "AddToCart");
          },
        });
        //request.success(function (data, textStatus, jqXHR){
        //	storefront.resetDeliveryOptions();
        //	nav.reload();
        //	storefront_actions.reload();
        //	fbq('track', 'AddToCart');
        //});
        request.fail(function (data, textStatus, errorThrown) {
          $formGroup = $("." + data.responseJSON.field).parents(".form-group");

          $formGroup.find(".help-block").text(data.responseJSON.error).show();
          $formGroup.addClass("has-error");
        });
        request.always(function () {
          storefront.enableSubmitButton();
        });
      }),
      // about text
      storefront.vars.$moreAbout.on("click", function (e) {
        storefront.vars.$about.children("p").hide();
        storefront.vars.$about.children("div").show();
        e.preventDefault();
      });
    storefront.vars.$lessAbout.on("click", function (e) {
      storefront.vars.$about.children("div").hide();
      storefront.vars.$about.children("p:first-child").show();
      e.preventDefault();
    });
  },

  selectDeliveryOption: function (method) {
    storefront.vars.$deliveryOptions.hide();
    storefront.vars.$deliveryOptions.find(":input").attr("disabled", true);
    $(".delivery-" + method).show();
    $(".delivery-" + method)
      .find(":input")
      .attr("disabled", false);
    $("#cart_item_fulfillment_method_" + method).prop("checked", true);
  },

  enableSubmitButton: function () {
    setTimeout(function () {
      storefront.vars.$cartItemFormSubmit.attr("disabled", false);
    }, 500);
  },

  validateGift: function () {
    var requiredFields = new Array(
      "recipient_name",
      "recipient_email",
      "sender_name",
      "sender_reply_to"
    );
    var hasErrors = false;
    var $submit = $("input[type=submit]");
    $(".form-group").removeClass("has-error");
    $(".form-group").find(".help-block").html("").hide();

    if (storefront.vars.$sendGiftSelect.val() == "future")
      requiredFields.push("send_date");
    $.each(requiredFields, function (i, str) {
      var $el = $("#" + storefront.vars.giftIdPrepend + "_" + str);

      if (!$el.val()) {
        hasErrors = true;
        $el
          .closest(".form-group")
          .find(".help-block")
          .html("This field is required")
          .show();
        $el.closest(".form-group").addClass("has-error");
      }
    });

    var recipient = $("#" + storefront.vars.giftIdPrepend + "_recipient_email");
    var reply_to = $("#" + storefront.vars.giftIdPrepend + "_sender_reply_to");
    var lower;
    if (recipient.val()) {
      lower = recipient.val().toLowerCase();
    }

    if (
      lower &&
      (lower == reply_to.val().toLowerCase() || loggedInUserEmail == lower)
    ) {
      hasErrors = true;
      var msg =
        "Gifting is for sending a gift to another person. To purchase a gift for yourself, select the 'Save it to my account' Delivery option above. Otherwise choose a different recipient email address.";
      $submit.closest(".form-group").find(".help-block").html(msg).show();
      $submit.closest(".form-group").addClass("has-error");
    }

    return !hasErrors;
  },

  resetGiftDefault: function () {
    storefront.vars.$sendGiftFutureHelp.hide();
    storefront.vars.$sendGiftFuture.removeClass('d-flex').addClass('d-none');
    storefront.vars.$sendGiftFuture.find(":input").attr("disabled", true);
  },

  resetDeliveryOptions: function () {
    $("#cart_item_gift_attributes_recipient_name").val("");
    $("#cart_item_gift_attributes_recipient_email").val("");
  },
};

// === reward modal ============= //
var rewardModal = {
  init: function () {
    rewardModal.vars = {
      $body: $("body"),
      form: ".reward-trigger-form",
      formEmail: ".reward-trigger-form-email",
      formMonth: ".reward-trigger-form-month",
      formDay: ".reward-trigger-form-day",
      formHelp: ".reward-trigger-form-help",
      submitBtn: ".btn",
    };
    rewardModal.bindEvents();
  },
  bindEvents: function () {
    $("#myModal").on("hidden.bs.modal", function () {
      // do something…
    });

    // on submit
    rewardModal.vars.$body.on("submit", rewardModal.vars.form, function (e) {
      e.preventDefault();
      var $form = $(this);
      var $name = $form.find(rewardModal.vars.formName);
      var $email = $form.find(rewardModal.vars.formEmail);
      var $month = $form.find(rewardModal.vars.formMonth);
      var $day = $form.find(rewardModal.vars.formDay);
      var $btn = $form.find(rewardModal.vars.submitBtn);
      var $help = $form.find(rewardModal.vars.formHelp);
      $email.closest(".form-group").removeClass("has-error");
      $month.closest(".form-group").removeClass("has-error");
      $help.hide();
      var formIsValid = true;
      if (!validateEmail($email.val())) {
        $email.closest(".form-group").addClass("has-error");
        formIsValid = false;
      }
      if ($month.length > 0) {
        if (!$month.val() || !$day.val()) {
          $month.closest(".form-group").addClass("has-error");
          formIsValid = false;
        }
      }
      if (!formIsValid) {
        $btn.attr("disabled", false);
        return;
      }
      $btn.attr("disabled", true);
      var request = $.ajax({
        url: $form.attr("action"),
        data: $form.serialize(),
        type: "post",
      });
      request.done(function (data, textStatus, jqXHR) {
        $name.val("");
        $email.val("");
        $month.val("");
        $day.val("");
        $help.show().html(data.message);
      });
      request.fail(function (jqXHR, textStatus, errorThrown) {
        alert("Sorry, there was an error");
      });
      request.always(function () {
        $btn.attr("disabled", false);
      });
    });
  },
};

// === discount sale specific === //
var discountSale = {
  init: function () {
    discountSale.vars = {
      $body: $("body"),
      $filters: $(".discount-sale-filters").find("a"),
      $results: $("#discount-sale-results"),
      addToCartBtn: ".cart-item-form :submit",
      modalTrigger: ".modal-trigger-alt",
      $cartSubtotalDivs: $(".cart-subtotal"),
    };
    //$(".sidebar-affix").affix({
    //	offset : {
    //		top: function() {
    //			return (this.top = $("#hero").outerHeight(true)-40)
    //		},
    //		bottom: function() {
    //			return (this.bottom = $("#footer").outerHeight(true)+40)
    //		}
    //	}
    //});
    //$('.sidebar-affix').css('top','70px');
    discountSale.bindEvents();
  },

  loadResults: function (el) {
    discountSale.vars.$results.load($(el).attr("href"));
  },

  bindEvents: function () {
    // modal trigger alt
    discountSale.vars.$results.on(
      "click",
      discountSale.vars.modalTrigger,
      function (e) {
        e.preventDefault();
        modalHelpers.loadModal(discountSale.vars.$body, $(this), false);
      }
    );

    // filters
    discountSale.vars.$filters.on("click", function (e) {
      e.preventDefault();
      // if active, don't reload
      var $li = $(this).closest("li");
      if ($li.hasClass("active")) {
        console.log("return");
        return;
      } else {
        discountSale.vars.$filters
          .closest("ul")
          .find("li")
          .removeClass("active");
        $li.addClass("active");
        discountSale.loadResults(this);
      }
    });

    // pagination
    discountSale.vars.$results.on("click", ".pagination a", function (e) {
      e.preventDefault();
      discountSale.loadResults(this);
    });

    // cart item submit
    discountSale.vars.$results.on(
      "click",
      discountSale.vars.addToCartBtn,
      function (e) {
        e.preventDefault();
        $(this).attr("disabled", true);
        var $btn = $(this);
        var $form = $(this).closest("form");
        var id = $form
          .find("input[name=cart_item\\[product_option_id\\]]")
          .val();
        var $row = $("#row-" + id);
        var request = $.ajax({
          url: $form.attr("action"),
          data: $form.serialize(),
          type: "post",
        });
        request.done(function (data, textStatus, jqXHR) {
          console.log(data);
          if (data.quantity_added > 0) {
            nav.reload();
            $row.load("/cart/discount_sale/product_options/" + id);
            discountSale.vars.$cartSubtotalDivs.load("/cart/carts/subtotal");
          } else {
            alert(
              "Sorry, we could not fulfill your request. That item has either sold out or you have reached your limit."
            );
          }
        });
        request.fail(function (jqXHR, textStatus, errorThrown) {
          alert("Sorry, there was an error");
        });
        request.always(function () {
          //storefront.enableSubmitButton();
          console.log("always");
        });
      }
    );
  },
};

// === slider === //
var slider = {
  init: function () {
    slider.elements = {
      $slider: $("#slider"),
      $nav: $(".slider-nav"),
      $arrows: $(".slider-nav a"),
      $sliderThis: null,
    };
    if (document.getElementById("slider") != null){
      slider.start();
      slider.bindEvents();
    }
  },

  bindEvents: function () {
    slider.elements.$slider.on("mouseover", function (e) {
      var width = $(window).width();
      if (width > 992) slider.elements.$nav.show();
    });
    slider.elements.$slider.on("mouseout", function (e) {
      slider.elements.$nav.hide();
    });
    slider.elements.$arrows.on("mouseover", function (e) {
      slider.elements.$nav.show();
    });
    slider.elements.$arrows.on("click", function (e) {
      if (this.className == "next") {
        slider.elements.$sliderThis.next();
      } else {
        slider.elements.$sliderThis.prev();
      }
    });
  },

  start: function () {
    slider.elements.$sliderThis = slider.elements.$slider
      .Swipe({
        startSlide: 0,
        speed: 500,
        auto: 3000,
        continuous: true,
        disableScroll: false,
        stopPropagation: false,
        callback: function (index, elem) {},
        transitionEnd: function (index, elem) {},
      })
      .data("Swipe");
  },
};

// index

$(document).on("turbolinks:load", slider.init);
$(document).on("turbolinks:load", storefront.init);
$(document).on("turbolinks:load", discountSale.init);
$(document).on("turbolinks:load", rewardModal.init);


var validateEmail = function(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}
