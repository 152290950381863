$(".table tbody tr").hover(
  function()
  {
		//$(this).children('td.name').children('ul.action-links').css('visibility','visible');
	},
  function()
  {
		//$(this).children('td.name').children('ul.action-links').css('visibility','hidden');
	}
);
import 'bootstrap/js/dist/modal';

// === global modal helpers === //
window.modalHelpers = {

	// === load and attach modal == //
	loadModal : function($page, $obj, isForm) {

		var url = $obj.attr('href');
		var id = modalHelpers.getModalId($obj.attr("id"));
		var title = $obj.attr("title");
		var submitText = $obj.data('submit-text');
		var submitCssClass = $obj.data('submit-css-class');

		// show loading modal
		var $modal = $("#"+id);
    if (!$modal.length) {
			$modal = modalHelpers.$formModal({
				id: id,
				title: title,
				submitText: submitText,
				submitCssClass: submitCssClass,
				isForm: isForm
			});
			$modal.appendTo($page);
			modalHelpers.loadRemoteContentIntoModal($modal, url);
		} else {
			$modal.modal("show");
		}

	},
	// === find form in a modal === //
	findForm : function(btn) {
		return $(btn).closest(".modal-footer").prevAll(".modal-body").find("form");
	},
	// === load content into a modal === //
	loadRemoteContentIntoModal : function($modal, url, settings) {

		// hide footer, probably buttons
		var $footer = $modal.children('.modal-footer');
		$footer.hide();

		// load modal and loader
		$modal.modal("show");
    
		var $loader = $modal.find("#modal-loading");
		$loader.show();

		// ajax
		var request = $.ajax({
	    url: url,
	    type: "get"
    });

		// success
		request.done(function (data, textStatus, jqXHR){
			$loader.hide();
			$footer.show();
			$modal.find('.modal-body').html(data);
		});

		// fail
    request.fail(function (jqXHR, textStatus, errorThrown){
			alert("There was an error, please try again");
		});

		// either way
		request.always(function () {
			$loader.hide();
		});

	},

	// === blank modal template === //
	$formModal : function(settings) {

		var config = {
			id : "modal-"+new Date().getTime(),
			title : "Modal Title",
			isForm : true,
			submitText : "Save Changes",
			submitCssClass : "btn btn-primary",
    }
    $.extend( config, settings );
    config.modalClasses ||= '';
		// modal body
		var $modal = $("<div id='"+config.id+"' class='modal "+config.modalClasses+" fade' tabindex='-1' role='dialog' aria-labelledby='label-"+config.id+"' aria-hidden='true'></div>");
		var $dialog = $("<div class='modal-dialog'></div>");
		var $content = $("<div class='modal-content'></div>");

		// header
		var $header = $("<div class='modal-header'></div>");
		$("<h4 class='modal-title w-100'>"  + config.title + "</h4>").appendTo($header);
    $("<button type='button' class='close' data-dismiss='modal' aria-hidden='true'><span aria-hidden='true'>&times;</span></button>").appendTo($header);
    $header.appendTo($content);

		// body + loader
		var $body = $("<div class='modal-body'></div>");
		$("<div id='modal-loading' style='display:none;'><img src='/images/partner/loading.gif'></div>").appendTo($body);
		$body.appendTo($content);

		// footer
		var $footer = $("<div class='modal-footer'></div>");
		$('<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>').appendTo($footer);
		if(config.isForm) {
			$('<button type="button" class="' + config.submitCssClass + ' modal-form-submit">' + config.submitText + '</button>').appendTo($footer);
		}
		$footer.appendTo($content);

		$content.appendTo($dialog);
		$dialog.appendTo($modal);

		return $modal;

	},

	getModalId : function(id) {
		return id+"-modal";
	}

}


//$(document).ready(function() {

	// == functions that could be used across any page === //
	var app = {

		init : function() {

			app.elements = {
				$body : $("body"),
				$page : $("#main"),
				$modalFormTriggers : $(".modal-form-trigger"),
				$modalTriggers : $(".modal-trigger"),
				modalFormSubmit : ".modal-form-submit"
			}

		   // allow overriding the default config
			app.bindEvents();
			app.detectFadingAlert();

		},

		bindEvents : function() {

			app.elements.$modalFormTriggers.on('click', function(e) {
				e.preventDefault();
				modalHelpers.loadModal(app.elements.$body, $(this), true);
			});
			app.elements.$modalTriggers.on('click', function(e) {
				e.preventDefault();
				modalHelpers.loadModal(app.elements.$body, $(this), false);
			});
			app.elements.$body.on('click', app.elements.modalFormSubmit, function(e) {
			  e.preventDefault();
			  $(app.elements.modalFormSubmit).attr('disabled', true);
			  modalHelpers.findForm($(this)).submit();
			});

		},

		// ==== Fading Alerts === //
		detectFadingAlert : function() {
			var $alert = $('.fading-alert');
			if ($alert.length > 0) {
		    $alert.show().animate({height: $alert.outerHeight()}, 300);
		    window.setTimeout(function() {
		      $alert.fadeOut(600);
		    }, 15000);
		  }
		}

	}
  $(document).on('turbolinks:load', app.init)
	//$(document).ready(app.init);

//});
